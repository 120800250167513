export default $(document).ready(() => {
  // This will close all the other dropdowns that are currently open
  // when clicked on a dropdown or clicked outside of a dropdown
  $(".nav-dropdown-toggle").on("click", closeOpenDropdowns);

  $(document).on(
    "change",
    ".nav-dropdown > input[type='checkbox']:checked",
    (e) => {
      $(document).one("click", closeOpenDropdowns);
    }
  );

  function closeOpenDropdowns(e) {
    $(".nav-dropdown > input[type='checkbox']:checked").each(function( index, element ) {
      if (!$(element).next("label").is($(e.target).closest("label"))) {
        $(element).prop("checked", false);
      }
    });
  }

  const nav = document.querySelector(".navbar");
  const mobileContainer = document.querySelector(".mobile-container");
  document.addEventListener("click", toggleMobileMenu);

  function toggleMobileMenu(e) {
    const mobileIcon = e.target.closest(".hamburger__icon");
    if (!mobileIcon) {
      return;
    }
    const menuIcon = document.querySelector(".menu-icon");
    const xIcon = document.querySelector(".x-icon");
    if (menuIcon.classList.contains("d-none")) {
      document.body.classList.remove("lock");
      menuIcon.classList.remove("d-none");
      xIcon.classList.add("d-none");
      mobileContainer.classList.add("d-none");
    } else {
      menuIcon.classList.add("d-none");
      xIcon.classList.remove("d-none");
      mobileContainer.classList.remove("d-none");
      document.body.classList.add("lock");
      nav.scrollIntoView();
    }
  }
});
