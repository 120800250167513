// show the first carousel (if there is more than 1)
const carousels = Array.from(document.querySelectorAll(".carousel.slide"));
if (carousels.length && carousels.length > 1) {
  carousels.forEach((carousel, i) => (carousel.hidden = i !== 0));
}

export const showCarousel = (id) => {
  // show the carousel for this variant
  const carousels = Array.from(document.querySelectorAll(".carousel.slide"));
  if (carousels.length && carousels.length > 1) {
    carousels.forEach((carousel) => (carousel.hidden = true));
    document.getElementById(id).hidden = false;
  }
};
