const isPrivacyPage = location.pathname === '/policies/privacy-policy/';

export default $(document).ready(e => {
  let config = JSON.parse(localStorage.getItem('cookie_config'));
  if (config) {
    enable3rdParty(config);
  }
  const monster = renderCookieMonster(config || { f: true, m: true, a: true });
  monster.addEventListener('click', function(e) {
    switch(e.target.dataset.action) {
      case 'accept':
        monster.hidden = true;
        config = JSON.parse(localStorage.getItem('cookie_config')) || { f: true, m: true, a: true };
        localStorage.setItem('cookie_config', JSON.stringify(config));
        enable3rdParty(config);
        break;
      case 'edit':
        monster.classList.add('cookie-monster--show-settings');
        break;
      case 'change':
        const type = e.target.dataset.cookie;
        const value = !config[type];
        updateCookieOption(e.target, value);
        config[type] = value;
        break;
      case 'save':
        monster.hidden = true;
        localStorage.setItem('cookie_config', JSON.stringify(config));
        enable3rdParty(config);
        break;
      default:
        break;
    }
  });
  function openCookieMonster() {
    monster.classList.add('cookie-monster--show-settings');
    if (config) {
      ['f', 'm', 'a'].forEach(function(type) {
        const option = monster.querySelector(`[data-cookie="${type}"]`)
        updateCookieOption(option, config[type]);
      });
    }
    showMonster();
  };
  document.body.insertBefore(monster, document.body.firstChild);
  if (!config) {
    monster.hidden = false;
    if (isPrivacyPage) {
      showMonster();
    } else {
      setTimeout(showMonster, 4500);
    }
  }
  function showMonster() {
    const close = monster.querySelector('.cookie-close');
    if (config) {
      close.setAttribute('title', 'Close');
      close.setAttribute('aria-label', 'Close');
    } else {
      close.setAttribute('title', 'Accept policy');
      close.setAttribute('aria-label', 'Accept policy');
      config = { f: true, m: true, a: true };
    }
    monster.hidden = false;
    monster.style.visibility = 'visible';
    monster.style.opacity = 1;
  }
});

function updateCookieOption(el, value) {
  el.textContent = value ? gettext('disable') : gettext('enable');
  el.parentNode.className = `cookie-${value ? 'on' : 'off'}`;
}

function enable3rdParty(conf) {
  const disabledScripts = document.querySelectorAll('script[type^="text/plain"]');
  Array.from(disabledScripts).forEach(function(script) {
    const scriptType = script.getAttribute('type');
    if (
      // at 11th pos "text/plain-<TYPE>" read char ('a', 'm' or 'f')
      // and check if entry is false in the conf object
      (conf && !conf[scriptType[11]])
      || (
        // or if there is an A/B test and script is not part of this test group
        window.testGroup
        && script.className.includes('group-')
        && !script.className.includes(`group-${testGroup}`))
    ) {
      // stop here
      return;
    }
    const parent = script.parentNode;
    parent.removeChild(script);
    // firefox doesn't want us to re-add the script to the dom
    // script.removeAttribute('type');
    // parent.appendChild(script);
    parent.appendChild(Object.assign(document.createElement('script'),
    script.getAttribute('src') && { src: script.getAttribute('src') },
    script.getAttribute('id') && { id: script.getAttribute('id') },
    script.getAttribute('async') && { async: true },
    script.getAttribute('defer') && { defer: true },
    script.innerHTML && { innerHTML: script.innerHTML }
    ));
  });
}

function renderCookieMonster(config) {
  return Object.assign(document.createElement('div'), {
    className: 'cookie-monster',
    style: 'opacity: 0; visibility: hidden;',
    hidden: true,
    innerHTML: `
    <h3 class="cookie-title">` +gettext("🍪 Cookie Monster!") +`</h3>
    <div class="cookie-description">
      <p>${isPrivacyPage ?
        gettext("Glad that you are reading the privacy policy 👍<br>"
        +"We use cookies to improve your browsing experience, "
        +"analyze site traffic and increase the performance "
        + "of our site. You can change the cookie settings below.")
        :
        gettext('We use cookies to improve your browsing experience, '
        +'analyze site traffic and increase the performance '
        +'of our site. You can change the cookie settings below.'
        +'If you want to know more, we prepared a '
        +'<a target="_blank" href="https://bitbox.swiss/policies/privacy-policy/">privacy policy</a>')
      }</p>
      <button type="button" data-action="accept" class="button btn-primary">
        `+gettext("OK")+`
      </button>
      <button type="button" data-action="edit" class="button btn-generic">
        `+gettext("Cookie settings")+`
      </button>
    </div>
    <div class="cookie-settings">
      <p>
        `+gettext("We use cookies to improve your browsing experience, "
        +"analyze site traffic and increase the performance "
        +"of our site. ") + `${isPrivacyPage ? '' : gettext('If you want to'
        +'know more, we prepared a <a target="_blank" href="https://bitbox.swiss/policies/privacy-policy/">'
        +'privacy policy</a>')
        }
      </p>
      <ul class="cookie-control">
        <li class="cookie-${config.f ? 'on' : 'off'}">
          <strong>` +gettext("Functional")+ `</strong>
          ` +gettext("Enhancing your browsing experience, for example "
          +"the support tool to communicate with us.")+
          `<button type="button" data-action="change" data-cookie="f">
            ${config.f ? gettext('disable') : gettext('enable')}
          </button>
        </li>
        <li class="cookie-${config.m ? 'on' : 'off'}">
          <strong>` +gettext("Marketing")+ `</strong>
          ` +gettext("We can deliver content that is relevant to you.")+ `
          <button type="button" data-action="change" data-cookie="m">
            ${config.m ? gettext('disable') : gettext('enable')}
          </button>
        </li>
        <li class="cookie-on cookie-readonly">
          <strong>` +gettext("Essential")+ `</strong>
          ` +gettext("Some cookies are necessary for the site to work,"
          +"for example the settings in this dialog & basic site analytics. "
          +"These cannot be turned off.")+ `
          <button readonly disabled>`+
            gettext("enabled")+
          `</button>
        </li>
      </ul>
      <div class="cookie-footer">
        <button type="button" data-action="save" class="button btn-primary">`+
          gettext("Save")+
        `</button>
        <span class="cookie-info">`+
          gettext("You can change this at any time.")+
        `</span>
      </div>
    </div>
    <button type="button" data-action="accept" class="cookie-close" title="Close" aria-label="Close">
    ✕
    </button>
  `});
}
