export function showResellerNote(parent) {
  const resellerNote = parent.querySelector("[data-reseller-note]");
  const countryDropdown = document.querySelector("#id_country");
  if (resellerNote && countryDropdown) {
    const countryCode = countryDropdown.value;
    const countryName =
      countryDropdown.options[countryDropdown.selectedIndex].textContent;
    const hostname = location.hostname === 'shop.bitbox.swiss'
      ? "bitbox.swiss"
      : document.body.dataset.websiteurl;

    fetch(`https://${hostname}/buy/resellers.json`)
      .then((data) => data.json())
      .then((resellers) =>
        resellers.filter((reseller) => reseller.shopnote === true)
      )
      .then((resellers) =>
        resellers.filter(
          (reseller) =>
            reseller.isocode === countryCode ||
            reseller.countries.includes(countryName)
        )
      )
      .then((resellers) => {
        if (resellers.length) {
          resellerNote.hidden = false;
          const resellerLink = document.querySelector("[data-reseller-link]");
          resellerLink.href = `https://${hostname}/buy/?search=${countryName}`;
        }
      })
      .catch((error) => console.log(error));
  }
}

showResellerNote(document.body);
